import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { stringify } from 'qs';

import { formatDate } from '../../helpers';
import { RichTextBlockRenderer } from '../../components/renderers';

export default function BlogPost() {
	
	const [loading, setLoading] = useState(true);
	const [blogContent, setBlogContent] = useState(false);
		
	const blogSlug = useParams().blogSlug;
	
	var q = stringify({
		filters: {
			slug: {
				$eq: blogSlug
			}
		},
		populate: ['banner', 'seo']
	});
	
	useEffect(() => {
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/blogs?${q}`)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			if(data.data.length>0) {
				console.log(data.data[0]);
				setBlogContent(data.data[0]);
				setLoading(false);
			}
		})
		.catch((error) => {
			console.log(error);
			setLoading(false);
		});
	}, [q]);
	
	if(blogContent) {
		var structuredData = {
			"@context": "https://schema.org",
			"@type": "BlogPosting",
			"headline": blogContent.title,
			"image": [
				blogContent.banner.url
			 ],
			"datePublished": blogContent.publicationDate,
			"author": [{
					"@type": "Person",
					"name": "Max Naylor"
			}]
		}
	}
	
	return(
		<div className="container external blog">
			{loading ? (
				<>
					<div className="blog-container blog-intro">
						<div className="prose">
							<h1 className="skeleton">Lorem ipsum dolar sit amet</h1>			
							<p className="standfirst skeleton">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ullamcorper velit leo, et posuere velit dapibus sed.</p>		
						</div>
						<div className="blog-author">		
							<div className="mugshot skeleton" />
							<div className="blog-author-details">
								<div className="skeleton"><strong>Max Naylor</strong></div>
								<div className="skeleton">1 January 2025</div>
							</div>
						</div>
					</div>
					<div className="blog-banner skeleton"></div>
				</>
			) : (
				<>
					<Helmet>
						<title>{`${blogContent.title} | Blog | Learn Icelandic`}</title>
						<meta name="title" content={`${blogContent.seo.metaTitle} | Learn Icelandic`} />
						<meta name="description" content={blogContent.seo.metaDescription} />
					</Helmet>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: JSON.stringify(structuredData),
						}}
					/>
					<div className="blog-container blog-intro">
						<div className="prose">
							<p className="no-padding no-margin"><a className="blog-link" href="/blog">Blog</a></p>
							<h1>{blogContent.title}</h1>
							{blogContent.standfirst && (
								<p className="standfirst">{blogContent.standfirst}</p>
							)}					
						</div>
						<div className="blog-author">		
							<img className="mugshot" src="/images/mugshot.png" alt="Portrait of Max Naylor" />
							<div className="blog-author-details">
								<div><strong>Max Naylor</strong></div>
								<div>{formatDate(blogContent.publicationDate)}</div>
							</div>
						</div>
					</div>
					{blogContent.banner && (
						<div className="blog-banner">
							<img className="blog-banner-image" src={blogContent.banner.url} alt={blogContent.banner.alternativeText} />
						</div>
					)}	
					<div className="blog-container blog-body">									
						<div className="prose">
							<RichTextBlockRenderer content={blogContent.content} />
						</div>
					</div>
				</>
			)}			
		</div>
	)
	
}