import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { stringify } from 'qs';

import { Button } from '../../components/atoms';
import CourseCard from '../../components/courseCard';

export default function HomePrelaunch() {
  
	const [disabled, setDisabled] = useState(false);
	const [signupSuccess, setSignupSuccess] = useState(false);
	
	const { register, handleSubmit, formState: { errors } } = useForm();
	
	const navigate = useNavigate();
	
	function handleMailingListSignup(formData) {
		setDisabled(true);
		const signupQ = stringify({
			email: formData.email,
			firstName: formData.firstName,
			referral: "website-prelaunch"
		});
		fetch(`${process.env.REACT_APP_NODE_BACKEND}/newsletterSignup?${signupQ}`, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${process.env.REACT_APP_NODE_BACKEND_AUTH_TOKEN}`
			},
		})
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			if(!data.error) {
				setSignupSuccess(true);
			} else {
				console.error(data.error.message);
			}			
		})
		.catch((error) => {
			console.log(error);
		});
	}
	
	useEffect(() => {
		if(!signupSuccess) {
			document.getElementById('firstName').focus();
		}		
	}, [signupSuccess]);
	
	return (
		<>
			<header 
				className="homepage-banner" 
				style={{
					backgroundImage: "linear-gradient(to bottom, rgba(0, 0, 0, 00), rgba(0, 0, 0, 0.5)), url('/images/puffins-on-cliff.jpg')"
				}}>
				<div className="container">
					<div className="blurb">					
						<p><span className="highlighted">Coming early 2025</span></p>
						<div><h1>A new way to learn Icelandic</h1></div>
					</div>
					<div className="card signup-form">
						{signupSuccess ? (
							<div className="signup-success">
								<img src="/images/icon-success.svg" width="42" />
								Thanks for registering for updates! We’ll keep you posted via email.
							</div>
						) : (
							<>
								<div className="card-blurb">
									<h2 className="no-padding">Be first to know</h2>
									<p>Learn Icelandic will be launching in <strong>early 2025</strong>. Sign up for email updates to get early access.</p>
								</div>
								<form id="mailing-list" onSubmit={handleSubmit(handleMailingListSignup)}>
									<div className="form-row">
										<label htmlFor="firstname">First name</label>
										<input 
											type="text"
											id="firstName"
											name="firstName"
											spellCheck="off"
											{...register('firstName', { required: true })}
											aria-invalid={errors.firstName ? "true" : "false"}
										/>
									</div>
									<div className="form-row">
										<label htmlFor="firstname">Email address</label>
										<input 
											type="email"
											id="email"
											name="email"
											spellCheck="off"
											{...register('email', { required: true, pattern: /^[-a-zA-Z0-9._%±]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ })}
											aria-invalid={errors.email ? "true" : "false"}
										/>
									</div>
									<div className="form-row padded">
										<Button disabled={disabled}>Register for updates</Button>
									</div>
								</form>
							</>
						)}						
					</div>
				</div>
			</header>
			<section className="homepage-usps">
				<div className="container">
					<h2>What will Learn Icelandic be like?</h2>
					<div className="card-wrapper">
						<div className="card info">
							<div className="icon large orange">
								<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M40.4688 21.7188L18.625 43.5625L15.4375 40.375L15.8125 40H11.5C10.6562 40 10 39.3438 10 38.5V34.1875L9.625 34.5625C9.15625 35.0312 8.875 35.5 8.6875 36.1562L6.53125 43.4688L13.8438 41.3125C14.4062 41.125 14.9688 40.8438 15.4375 40.375L18.625 43.5625C17.6875 44.5 16.4688 45.25 15.1562 45.625L3.8125 48.9062C3.0625 49.1875 2.21875 49 1.65625 48.3438C1 47.7812 0.8125 46.9375 1 46.1875L4.375 34.8438C4.75 33.5312 5.5 32.3125 6.4375 31.375L28.2812 9.53125L40.4688 21.7188ZM47.125 6.53125C49.4688 8.875 49.4688 12.7188 47.125 15.0625L42.625 19.5625L30.4375 7.375L34.9375 2.875C37.2812 0.53125 41.125 0.53125 43.4688 2.875L47.125 6.53125Z" fill="#D83D01"/>
								</svg>
							</div>
							<div className="content-wrapper">
								<h3>Expertly crafted materials</h3>
								<p>Courses designed by a professional with 5 years’ experience teaching at university level.</p>
							</div>
						</div>
						<div className="card info">		
							<div className="icon large orange">
								<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M26.4375 32.25L23.25 35.3438C22.875 35.8125 22.3125 36 21.75 36H18V39.75C18 41.0625 16.9688 42 15.75 42H12V45.75C12 47.0625 10.9688 48 9.75 48H2.25C0.9375 48 0 47.0625 0 45.75V38.25C0 37.6875 0.1875 37.125 0.65625 36.6562L15.75 21.5625C15.1875 19.9688 15 18.2812 15 16.5C15 7.40625 22.3125 0 31.5 0C40.5938 0 48 7.40625 48 16.5C48 25.6875 40.5938 33 31.5 33C29.7188 33 28.0312 32.8125 26.4375 32.25ZM35.25 16.5C37.3125 16.5 39 14.9062 39 12.75C39 10.6875 37.3125 9 35.25 9C33.0938 9 31.5 10.6875 31.5 12.75C31.5 14.9062 33.0938 16.5 35.25 16.5Z" fill="#D83D01"/>
								</svg>
							</div>					
							<div className="content-wrapper">
								<h3>Easy to digest grammar</h3>
								<p>Start unlocking the power of grammar instead of fighting against it through simple explanations.</p>
							</div>
						</div>
						<div className="card info">			
							<div className="icon large orange">
								<svg width="62" height="50" viewBox="0 0 62 50" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.84375 1.9375C2.96875 0.71875 4.9375 0.71875 6.0625 1.9375L15.9062 11.7812V10C15.9062 8.40625 17.3125 7 18.9062 7C20.5938 7 21.9062 8.40625 21.9062 10V19C21.9062 19.4688 21.9062 19.8438 21.7188 20.2188C21.625 20.5 21.3438 20.875 21.0625 21.1562C20.7812 21.4375 20.4062 21.625 20.125 21.8125C19.75 22 19.375 22 18.9062 22H9.90625C8.3125 22 6.90625 20.6875 6.90625 19C6.90625 17.4062 8.3125 16 9.90625 16H11.6875L1.84375 6.15625C0.625 5.03125 0.625 3.0625 1.84375 1.9375ZM37 25C37 28.375 34.2812 31 31 31C27.625 31 25 28.375 25 25C25 21.7188 27.625 19 31 19C34.2812 19 37 21.7188 37 25ZM10 34C8.3125 34 7 32.6875 7 31C7 29.4062 8.3125 28 10 28H19C19.75 28 20.5 28.375 21.0625 28.9375C21.3438 29.2188 21.625 29.5 21.7188 29.875C21.9062 30.25 22 30.625 22 31V40C22 41.6875 20.5938 43 19 43C17.3125 43 16 41.6875 16 40V38.3125L6.0625 48.1562C4.9375 49.375 2.96875 49.375 1.84375 48.1562C0.625 47.0312 0.625 45.0625 1.84375 43.9375L11.6875 34H10ZM43 7C44.5938 7 46 8.40625 46 10V11.7812L55.8438 1.9375C56.9688 0.71875 58.9375 0.71875 60.0625 1.9375C61.2812 3.0625 61.2812 5.03125 60.0625 6.15625L50.2188 16H52C53.5938 16 55 17.4062 55 19C55 20.6875 53.5938 22 52 22H43C42.1562 22 41.4062 21.7188 40.8438 21.1562C40.5625 20.875 40.2812 20.5 40.1875 20.2188C40 19.8438 40 19.4688 40 19.0938V19V10C40 8.40625 41.3125 7 43 7ZM50.2188 34L60.0625 43.9375C61.2812 45.0625 61.2812 47.0312 60.0625 48.1562C58.9375 49.375 56.9688 49.375 55.8438 48.1562L46 38.3125V40C46 41.6875 44.5938 43 43 43C41.3125 43 40 41.6875 40 40V31C40 30.625 40 30.25 40.1875 29.875C40.2812 29.5 40.5625 29.2188 40.8438 28.9375C41.4062 28.375 42.1562 28 42.9062 28C42.9062 28 42.9062 28 43 28H52C53.5938 28 55 29.4062 55 31C55 32.6875 53.5938 34 52 34H50.2188Z" fill="#D83D01"/>
								</svg>
							</div>				
							<div className="content-wrapper">
								<h3>Beautiful learning environment</h3>
								<p>Designed to help you focus on learning Icelandic, not figuring out a complicated app.</p>
							</div>
						</div>
						<div className="card info">			
							<div className="icon large orange">
								<svg width="36" height="48" viewBox="0 0 36 48" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M33 0C34.5938 0 36 1.40625 36 3C36 4.6875 34.5938 6 33 6V7.03125C33 11.0625 31.4062 14.9062 28.5938 17.7188L22.2188 24L28.5938 30.375C31.4062 33.1875 33 37.0312 33 40.9688V42C34.5938 42 36 43.4062 36 45C36 46.6875 34.5938 48 33 48H3C1.3125 48 0 46.6875 0 45C0 43.4062 1.3125 42 3 42V40.9688C3 37.0312 4.5 33.1875 7.3125 30.375L13.6875 24L7.3125 17.7188C4.5 14.9062 3 11.0625 3 7.03125V6C1.3125 6 0 4.6875 0 3C0 1.40625 1.3125 0 3 0H33ZM10.4062 12H25.5C26.4375 10.5938 27 8.8125 27 7.03125V6H9V7.03125C9 8.8125 9.46875 10.5938 10.4062 12ZM10.4062 36H25.5C25.125 35.5312 24.75 35.0625 24.2812 34.6875L18 28.3125L11.625 34.6875C11.1562 35.0625 10.7812 35.5312 10.4062 36Z" fill="#D83D01"/>
								</svg>								
							</div>				
							<div className="content-wrapper">
								<h3>Go at a pace that suits you</h3>
								<p>Because all the courses are self-led, you can learn at a speed that you’re comfortable with.</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="homepage-about-me">
				<img className="hero-image" src="/images/icelandic-notebooks.jpg" alt="Handwritten notes about Icelandic vocabulary" />
				<div className="container about-me padded">
					<div className="blurb">
						<h2>About the creator</h2>
					</div>
					<div className="avatar-wrapper-home">
						<img className="mugshot" src="/images/mugshot.png" alt="Portrait of Max Naylor" />
						<p><strong>Max Naylor</strong><br />Icelandic teacher and course designer</p>
					</div>
					<div className="about-me-wrapper">
						<div className="blurb">
							<p>My own Icelandic learning journey began back in 2007, when I started teaching myself the language so I could understand the lyrics to Sigur Rós songs. Only later did I realise half of them are in a made-up language 😫</p>
							<p>I’ve had a passion for languages as long as I can remember, and I’m a true believer that <strong>anyone can learn a foreign language</strong>. You don’t have to be a genius or have a special gift.</p>
							<p>In my view, there are 2 reasons people fail at learning languages: bad teaching and lack of true motivation. My goal is to be the best teacher I can be, and hopefully my enthusiasm will rub off on you and keep you motivated! 💪</p>
							<p>I also have a strong interest in design. I harness this to create clear, beautiful materials that help you stay focused on learning, rather than being distracted.</p>
						</div>
						<ul className="about-me-timeline">
							<li><strong>2007</strong><br />Started teaching myself Icelandic</li>
							<li><strong>2013</strong><br />BA Icelandic from University College London</li>
							<li><strong>2014</strong><br />Started tutoring Icelandic</li>
							<li><strong>2017</strong><br />MA Icelandic Linguistics from Háskóli Íslands (University of Iceland)</li>
							<li><strong>2018–2022</strong><br />Taught Icelandic at University of Edinburgh</li>
							<li><strong>2019–2023</strong><br />Taught Árni Magnússon Institute summer course in Reykjavík</li>
							<li><strong>2023</strong><br />Icelandic Grammar Reference launches</li>
							<li><strong>2025</strong><br />Learn Icelandic launches</li>
						</ul>
						</div>
				</div>
			</section>
			<section className="homepage-more-icelandic">
				<div className="container">
					<h2>More from Learn Icelandic</h2>
					<div className="card-wrapper">
						<div className="card info">
							<div className="content-wrapper">
								<div className="icon large">
									<img src="images/icelandic-lessons-logo.svg" width="48px" alt="Learn Icelandic logo" />
								</div>
								<h3>Icelandic Grammar Reference</h3>
								<p>Detailed yet digestible explanations of every aspect of Icelandic grammar. All available for free.</p>							
							</div>
							<Link to="https://icelandicgrammar.com/" target="_new" role="button" className="full-width">
								<Button role="secondary" icon="external" iconPlacement="after">Visit Icelandic Grammar Reference</Button>
							</Link>
						</div>
						<div className="card info">
							<div className="content-wrapper">
								<div className="icon large">
									<img src="images/logo-youtube.svg" alt="YouTube logo" />
								</div>
								<h3>YouTube channel</h3>
								<p>Check out explanations of Icelandic grammar and videos featuring language learning tips.</p>
							</div>
							<Link to="https://www.youtube.com/@islenskukennarinn" target="_new" role="button" className="full-width">
								<Button role="secondary" icon="external" iconPlacement="after">Visit YouTube channel</Button>
							</Link>
						</div>
					</div>
				</div>
			</section>
		</>	
	)
}
