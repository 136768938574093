import { useEffect, useState } from 'react';

import { getExternalPage } from '../../fetches';

import { Spinner } from '../../components/atoms';
import { RichTextBlockRenderer } from '../../components/renderers';

export default function ExternalPage({
		slug
	}) {
		
	const [loading, setLoading] = useState(true);
	
	const [pageData, setPageData] = useState(null);
		
	useEffect(() => {
		if(!pageData) {
			getExternalPage(slug)
			.then((data) => {
				setLoading(false);
				if(data.data.length>0) {	
					console.log(data.data[0]);
					setPageData(data.data[0]);
				}
			})
			.catch((error) => {
				console.log(error);
				setLoading(false);
			});
		}		
	}, [pageData]);
			
	return(
		<div className="container external document">
			{loading ? (
				<div className="centred-content padded">
				<Spinner />
				</div>
			) : (
				<>
				<div className="prose"><h1>{pageData.title}</h1></div>
				<div className="prose">
					<RichTextBlockRenderer content={pageData.content} />
				</div>
				</>
			)}			
		</div>
	)
	
}