import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';

import { BreadcrumbsContext } from '../../contexts';

function LessonDropdown({
		courseSlug,
		title,
		visibleMenu,
		setVisibleMenu,
		parent,
		items, 
		path,
		onClick,
		handleDropdownToggle
	}) {
		
	const currentPath = useLocation().pathname;		
	const ref = useRef(null);
	
	console.log(courseSlug);

	return(
		<div className={`dropdown lesson-dropdown${visibleMenu === title ? (' visible') : ('')}`} ref={ref}>
			<ul className="mobile-only">
				<li>
					<Link
						className="link-color"
						to={`/c/${courseSlug}/`}
						onClick={currentPath !== courseSlug ? onClick : handleDropdownToggle}
					>
						← Back to course overview
					</Link>
				</li>
			</ul>
			<ul>
				{items.map((thisItem, i) =>
					<li key={i} className={`${currentPath === path+thisItem.attributes.slug ? (' active') : ('')}`}>
						<Link 
							to={`${path}${thisItem.attributes.slug}`}
							path="relative"
							onClick={currentPath !== path+thisItem.attributes.slug ? onClick : handleDropdownToggle}
						>
							<img 
								className="icon colour"
								src={`/images/icon-${thisItem.attributes.type}.svg`} 
								alt={thisItem.attributes.type} />
							{thisItem.attributes.longTitle ? thisItem.attributes.longTitle : thisItem.attributes.title}
						</Link>			
					</li>
				)}
			</ul>
		</div>
	)
}

function DropdownButton({
		courseSlug,
		title,
		items,
		path,
		onClick,
		visibleMenu,
		setVisibleMenu,
		handleDropdownToggle
	}) {
		
	const ref = useRef(null);
	
	function handleDropdownItemClick() {
		handleDropdownToggle();
		onClick();
	}
	
	return (
		<>
			<button 
				className={`menu-toggle${visibleMenu === title ? (' active') : ('')}`} 
				onClick={() => handleDropdownToggle(title)} 
				ref={ref}
			>
				<span>{title}</span>
				<svg  className="dropdown-arrow" width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.25 6.25C6.03906 6.25 5.85156 6.17969 5.71094 6.03906L1.21094 1.53906C0.90625 1.25781 0.90625 0.765625 1.21094 0.484375C1.49219 0.179688 1.98438 0.179688 2.26562 0.484375L6.25 4.44531L10.2109 0.484375C10.4922 0.179688 10.9844 0.179688 11.2656 0.484375C11.5703 0.765625 11.5703 1.25781 11.2656 1.53906L6.76562 6.03906C6.625 6.17969 6.4375 6.25 6.25 6.25Z" fill="#1C1E21" fillOpacity="0.5"/>
				</svg>								
			</button>		
			<LessonDropdown 
				courseSlug={courseSlug}
				title={title}
				visibleMenu={visibleMenu} 
				setVisibleMenu={setVisibleMenu}
				parent={ref}
				items={items} 
				path={path} 
				onClick={handleDropdownItemClick} 
				handleDropdownToggle={handleDropdownToggle}
			/>
		</>
	)
}

export default function Breadcrumbs({
		visibleMenu,
		setVisibleMenu,
		parent,
		handleDropdownToggle
	}) {
	
	const breadcrumbs = useContext(BreadcrumbsContext);
	
	useEffect(() => {
		if(!breadcrumbs.breadcrumbs) {
			var newBreadcrumbs = [{
				path: '/dashboard',
				title: 'Learn Icelandic',
				mobileStyle: 'hidden'	
			}];
			breadcrumbs.setBreadcrumbs(newBreadcrumbs);
		}	
	}, [breadcrumbs.breadcrumbs]);
	
	if(breadcrumbs.breadcrumbs) {
		return (
			<ol className="breadcrumbs">
				{breadcrumbs.breadcrumbs.map((thisLink, i) =>
					<li key={i} className={thisLink.mobileStyle}>				
						{i === 0 ? (								
							<Link to={thisLink.path} onClick={() => setVisibleMenu()}>
								<img src="/images/icelandic-lessons-logo.svg" width="16px" />									
								<span className="collapsible-text"><strong>{thisLink.title}</strong></span>
							</Link>
						) : (						
							<>	
								{thisLink.dropdownItems ? (		
									<DropdownButton 
										courseSlug={thisLink.courseSlug}
										title={thisLink.title} 
										items={thisLink.dropdownItems} 
										path={thisLink.path} 
										onClick={thisLink.dropdownOnClick}
										visibleMenu={visibleMenu}
										setVisibleMenu={setVisibleMenu}
										handleDropdownToggle={handleDropdownToggle}
									/>		
								) : (
									<Link to={thisLink.path}>{thisLink.title}</Link>		
								)}
							</>
						)}
						{i < breadcrumbs.breadcrumbs.length-1 && (				
						<span className="separator">				
								<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M1.25 10.75C1.03906 10.75 0.851562 10.6797 0.710938 10.5391C0.40625 10.2578 0.40625 9.76562 0.710938 9.48438L4.67188 5.5L0.710938 1.53906C0.40625 1.25781 0.40625 0.765625 0.710938 0.484375C0.992188 0.179688 1.48438 0.179688 1.76562 0.484375L6.26562 4.98438C6.57031 5.26562 6.57031 5.75781 6.26562 6.03906L1.76562 10.5391C1.625 10.6797 1.4375 10.75 1.25 10.75Z" fill="#1C1E21" fillOpacity="0.5"/>
								</svg>
						</span>
						)}							
					</li>	
				)}				
			</ol>
		)
	}	
	
}