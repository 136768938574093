import { useContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { getToken } from './helpers';

import { useAuthContext } from './contexts';

import AlertBanner from './components/nav/alertBanner';
import Nav from './components/nav/nav';
import Footer from './components/nav/footer';
import ReportProblem from './components/reportProblem';

import NotFound from './pages/notFound';

import Home from './pages/external/home';
import HomePrelaunch from './pages/external/homePrelaunch';

import ExternalPage from './pages/external/externalPage';
import Blog from './pages/external/blog';
import BlogPost from './pages/external/blogPost';

import Login from './pages/external/login';
import Signup from './pages/external/signup/signup';
import ResetPassword from './pages/external/resetPassword';

import Onboarding from './pages/onboarding';
import Dashboard from './pages/dashboard/dashboard';
import Course from './pages/course/course';
import Lesson from './pages/lesson';
import LessonPage from './pages/lessonPage/lessonPage';
import Account from './pages/account/account';

import Product from './pages/store/product';

import EnterpriseOverview from './pages/enterprise/enterpriseOverview';
import EnterpriseUsers from './pages/enterprise/enterpriseUsers';
import EnterpriseSettings from './pages/enterprise/enterpriseSettings';

import { DialogContext } from './contexts';

import { ErrorBoundary } from 'react-error-boundary';

import { errorDialog } from './components/errorDialog';

export default function Main() {
	
	const appearance = localStorage.getItem('appearance');
	const user = useAuthContext();
	
	if(appearance === 'light') {
		document.body.classList.add('light');
	} else if(appearance === 'dark') {
		document.body.classList.add('dark');
	} else {
		document.body.classList.remove('dark');
		document.body.classList.remove('light');
	}
	
	const dialog = useContext(DialogContext);
	
	const siteMode = process.env.REACT_APP_SITE_MODE;
	
	return(
		<ErrorBoundary fallbackRender={errorDialog}>
			<Nav mode={siteMode} />
			<div className="body">
				<Routes>
					<Route path="404" element={<NotFound />} />
					<Route path="/not-found" element={<NotFound />} />
					{siteMode!=="beta" && (
						<>
							<Route path="/blog" element={<Blog />} />
							<Route path="/blog/:blogSlug" element={<BlogPost />} />
							<Route path="/privacy-notice" element={<ExternalPage slug="privacy-notice" />} />
						</>
					)}
					<Route path="/" element={siteMode==="pre-launch" ? <HomePrelaunch /> : siteMode==="beta" ? <Login /> :<Home />} />
					{siteMode!=="pre-launch" && (
						<>							
							<Route path="/store/p/:productSlug" element={<Product />} />
							<Route path="/dashboard" element={getToken() ? <Dashboard /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/onboarding" element={getToken() ? <Onboarding /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/login" element={<Login />} />
							<Route path="/signup" element={<Signup />} />
							<Route path="/reset-password" element={getToken() ? <Navigate to="/dashboard" /> : <ResetPassword />} />
							<Route path="/account" element={getToken() ? <Account /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/enterprise" element={getToken() ? <EnterpriseOverview /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/enterprise/users" element={getToken() ? <EnterpriseUsers /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/enterprise/settings" element={getToken() ? <EnterpriseSettings /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/c/:courseSlug/:lessonSlug/:pageSlug" element={getToken() ? <LessonPage /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/c/:courseSlug/:lessonSlug"  element={getToken() ? <Lesson /> : <Navigate to="/login" state={window.location.pathname} />} />
							<Route path="/c/:courseSlug" element={getToken() ? <Course /> : <Navigate to="/login" state={window.location.pathname} />} />
						</>
					)}
					<Route path="*" element={<Navigate to="/not-found" replace />} />
				</Routes>		
			</div>	
			<ReportProblem />
			<Footer mode={siteMode} />
		</ErrorBoundary>
	)
}