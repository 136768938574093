import { Fragment } from 'react';
import Markdown from 'react-markdown';
import parse from 'html-react-parser';

import { useAuthContext } from '../contexts';

import { AudioButton } from './atoms';

import { Heading, List, Paradigm, Paragraph, UnorderedList } from './blocks';

export function RenderMarkdown(string) {
	return  <Markdown disallowedElements={["p"]} unwrapDisallowed={true}>{string}</Markdown>;
}

export function ReplaceHandlebars(string) {
	
	const user = useAuthContext();
	
	var newString = string;
	var today = new Date();
	var tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	var overmorrow = new Date();
	overmorrow.setDate(overmorrow.getDate() + 2);
	
	const dateFormat = {
		month: 'long',
		day: 'numeric',
	};
	
	if(string.match('{{userFirstName}}')) {
		if(user.user.firstName) {
			newString = string.replace('{{userFirstName}}', user.user.firstName);
		}
	}
	
	if(string.match('{{dateToday}}')) {
		newString = string.replace('{{dateToday}}', today.toLocaleDateString('is-IS', dateFormat));
	}
	
	if(string.match('{{dateTomorrow}')) {
		newString = string.replace('{{dateTomorrow}}', tomorrow.toLocaleDateString('is-IS', dateFormat));
	}
	
	if(string.match('{{dateOvermorrow}')) {
		newString = string.replace('{{dateOvermorrow}}', overmorrow.toLocaleDateString('is-IS', dateFormat));
	}
	
	return newString;
}

export function LineBreakRenderer(textObject) {
	var textContent = [];
	var lineBreakSplit = textObject.split(/\n/);
	if(lineBreakSplit.length > 1) {
		lineBreakSplit.map(function(thisLine, i) {
			textContent.push(<>{thisLine}<br key={i} /></>)
		});
	}
	return(
		textContent
	)
}

export function TextFormatter({ textObject, keyName, parseHTML }) {
	var textContent = [];
	var textString = ReplaceHandlebars(textObject.text);
	if(parseHTML===true) {
		textString = parse(ReplaceHandlebars(textObject.text));
	}
	if(textObject.bold && textObject.italic && textObject.underline) { 
		textContent.push(<strong key={keyName}><em><u>{textString}</u></em></strong>)
	} else if(textObject.bold && textObject.italic) { 
		textContent.push(<strong key={keyName}><em>{textString}</em></strong>) 
	} else if(textObject.bold) { 
		textContent.push(<strong key={keyName}>{textString}</strong>) 
	} else if(textObject.italic && textObject.underline) { 
		textContent.push(<em key={keyName}><u>{textString}</u></em>) 
	} else if(textObject.italic && textObject.strikethrough) { 
		textContent.push(<em key={keyName}><s>{textString}</s></em>) 
	} else if(textObject.italic) { 
		textContent.push(<em key={keyName}>{textString}</em>) 
	} else if(textObject.underline) { 
		textContent.push(<u key={keyName}>{textString}</u>) 
	} else if(textObject.strikethrough) { 
		textContent.push(<s key={keyName}>{textString}</s>) 
	} else {
		textContent.push(textString);
	}	
	return textContent;
}

export function TextRenderer({ textArray, parseHTML }) {
	var textContent = [];	
	{textArray.map(function(thisChild, k) {
		if(thisChild.type === "text") {
			textContent.push(<TextFormatter key={k} keyName={k} parseHTML={parseHTML} textObject={thisChild}  />)
		} else if(thisChild.type === "link") {
			var linkContent = [];
			textContent.push(
				<a href={thisChild.url} key={k} target="_new">
					{thisChild.children.map(function(thisTextChild, j) {
						linkContent.push(<TextFormatter textObject={thisTextChild} key={j} />)
					})}
					{linkContent}
				</a>
			)
		}							
	})}
	return textContent;
}

export function RichTextBlockRenderer({ audio, content, noptags, parseHTML }) {
	if(content) {
		var richTextContent = [];
		content.map(function(thisBlock, k) {		
			if(thisBlock.type === "paragraph") {
				richTextContent.push(
					<Paragraph key={k} audio={audio} content={thisBlock} noptags={noptags} parseHTML={true} />
				)
			} else if(thisBlock.type === "heading") {
				richTextContent.push(
					<Heading key={k} content={thisBlock} />
				)				
			} else if(thisBlock.type === "list") {
				richTextContent.push(
					<List key={k} content={thisBlock} parseHTML={true} />
				)
			} else if(thisBlock.type === "image") {
				richTextContent.push(
					<div className="block-image-wrapper">
						<img src={thisBlock.image.url} alt={thisBlock.image.alternativeText} />
					</div>
				)
			}
		});
		return richTextContent;
	}	
}

export function BlockRenderer(blocks) {
	var content = [];
	blocks.content.map(function(thisBlock, i) {
		if(thisBlock.__component === "lesson-page-blocks.paragraph") {
			content.push(
				<div className="prose" key={i}>
					<RichTextBlockRenderer content={thisBlock.content} />
				</div>
			)
		} else if(thisBlock.__component === "lesson-page-blocks.callout") {
			content.push(
				<div className={`callout ${thisBlock.type}`} key={i}>
					<RichTextBlockRenderer content={thisBlock.content} />
				</div>
			)
		} else if(thisBlock.__component === "lesson-page-blocks.paradigm") {
			content.push(
				<div className="paradigm" key={i}>
					<Paradigm content={thisBlock} />
				</div>
			)
		} else if(thisBlock.__component === "lesson-page-blocks.vocab-block") {
			if(thisBlock.hero) {
				content.push(		
					<div className="block-wrapper" key={i}>
						{thisBlock.row.map((thisRow, i) =>											
							<div className={`block hero col-${thisBlock.columns}${thisRow.class ? ` ${thisRow.class}` : ""}`} key={i}>			
								<RichTextBlockRenderer content={thisBlock.row[i].content} />
								{thisBlock.row[i].audio.data && (	
									<AudioButton src={`${thisBlock.row[i].audio.data.attributes.url}`} type={thisBlock.row[i].audio.data.attributes.mime} />
								)}
							</div>
						)}
					</div>
				)
			} else {
				content.push(		
					<div className="block vocab" key={i}>
						{thisBlock.image.data && (						
							<img 
								src={thisBlock.image.data.attributes.url}
								alt={thisBlock.image.data.attributes.alternativeText} 
								className={thisBlock.imageSize}
							/>
						)}
						<div className={`content${thisBlock.vAlign ? " "+thisBlock.vAlign : ""}`}>
							{[...Array(thisBlock.columns)].map((x, i) =>
								<div className={`col${thisBlock.columnsSizeToFit ? " to-fit" : ""}`} key={i}>
									{thisBlock.row.map((thisRow, j) =>		
										<Fragment key={j}>
											{thisRow.column === i+1 && (								
												<div className={`row${thisRow.gap ? " padded" : ""}${thisRow.isCentred ? " centred" : ""}${thisRow.isColumn ? " as-column" : ""}${thisRow.class ? ` ${thisRow.class}` : ""}`}>															
													{((thisRow.column === i+1) && (thisRow.image.data)) && (						
														<img 
															src={thisRow.image.data.attributes.url}
															alt={thisRow.image.data.attributes.alternativeText} 
															className={thisRow.imageSize}
														/>
													)}	
													<div className="prose">
														<RichTextBlockRenderer content={thisBlock.row[j].content} audio={thisBlock.row[j].audio.data} />
													</div>
												</div>
											)}
										</Fragment>
									)}
								</div>
							)}
						</div>
					</div>
				)
			}
		} 
	})
	return content;
}