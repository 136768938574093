import React from 'react';
import ReactDOM from 'react-dom/client';
import { useEffect } from 'react';

import { BrowserRouter, useLocation } from 'react-router-dom';

import i18n from './i18n';

import App from './app';

import { PostHogProvider } from 'posthog-js/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const env = process.env.NODE_ENV;

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}

root.render(
  <PostHogProvider 
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
  </PostHogProvider>
);
