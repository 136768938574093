import { useEffect, useState } from 'react';
import { stringify } from 'qs';

import { formatDate } from '../../helpers';

export default function Blog() {
	
	const [loading, setLoading] = useState(true);
	const [blogs, setBlogs] = useState(null);
	
	var q = stringify({
		sort: ['publicationDate:desc'],
		populate: ['banner']
	});
	
	useEffect(() => {
		fetch(`${process.env.REACT_APP_STRAPI_BACKEND}/api/blogs?${q}`)
		.then((res) => {
			return res.json();
		})
		.then((data) => {
			setLoading(false);
			if(data.data.length>0) {
				setBlogs(data.data);
			}
		})
		.catch((error) => {
			console.log(error);
			setLoading(false);
		});
	}, [q]);
	
	return(
		<div className="container external">
			<div className="prose">
				<h1>Blog</h1>
			</div>
			{loading ? (
				<div className="cards-wrapper blog-posts">
					<div className="card blog-post-card skeleton"></div>
					<div className="card blog-post-card skeleton"></div>
					<div className="card blog-post-card skeleton"></div>
					<div className="card blog-post-card skeleton"></div>
					<div className="card blog-post-card skeleton"></div>
					<div className="card blog-post-card skeleton"></div>
				</div>
			) : (
				<div className="cards-wrapper blog-posts">
					{!blogs ? (
						<div className="centred-content"><strong>Blog coming soon!</strong></div>
					) : (
						<>
							{blogs.map((thisBlog, i) =>
								<a href={`/blog/${thisBlog.slug}`} className="card blog-post-card" key={i}>
									<img src={thisBlog.banner.url} alt={thisBlog.banner.alternativeText} />
									<div className="blog-post-card-title">
										<h2>{thisBlog.title}</h2>
										<p>{formatDate(thisBlog.publicationDate, 'en-gb')}</p>
									</div>
								</a>
							)}
						</>
					)}		
				</div>
			)}			
		</div>
	)
	
}