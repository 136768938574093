import { RichTextBlockRenderer, TextRenderer } from './renderers';

import { AudioButton } from './atoms';

/* Basic HTML tags */

export function Heading({ content }) {
	switch(content.level) {
		case 2:
			return(
				<h2>
					<TextRenderer textArray={content.children} />
				</h2>
			);
			break;
		case 3:
			return(
				<h3>
					<TextRenderer textArray={content.children} />
				</h3>
			);
			break;
		case 4:
			return(
				<h4>
					<TextRenderer textArray={content.children} />
				</h4>
			);
			break;
	}				
}

export function Paragraph({ audio, content, noptags, parseHTML }) {
	if(noptags) {					
		return(
			<TextRenderer textArray={content.children} parseHTML={parseHTML} />
		)
	} else {					
		return(
			<p>
				<TextRenderer textArray={content.children} parseHTML={parseHTML} />
				{audio && (	
					<AudioButton src={`${audio.attributes.url}`} type={audio.attributes.mime} />
				)}
			</p>
		)
	}
}

export function List({ content, parseHTML }) {
	if(content.format === "unordered") {
		return <UnorderedList content={content} parseHTML={parseHTML} />
	} else if(content.format === "ordered") {
		return <OrderedList content={content} parseHTML={parseHTML} />
	}
}

export function UnorderedList({ content, parseHTML }) {
	console.log(parseHTML);
	var listItemContent = [];	
	return(
		<ul>
			{content.children.map(function(thisChild, j) {
				if(thisChild.type==="list-item") {
					listItemContent.push(
						<li key={j}>
							<TextRenderer textArray={thisChild.children} parseHTML={parseHTML} />
						</li>
					)
				} else if(thisChild.type==="list") {
					console.log(thisChild.children);
					listItemContent.push(
						<UnorderedList key={j} content={thisChild} parseHTML={parseHTML} />
					)
				}						
			})}
			{listItemContent}
		</ul>
	)
}

export function OrderedList({ content, parseHTML }) {
	var listItemContent = [];	
	return(
		<ol>
			{content.children.map(function(thisChild, j) {
				if(thisChild.type==="list-item") {
					listItemContent.push(
						<li key={j}>
							<TextRenderer textArray={thisChild.children} parseHTML={parseHTML}/>
						</li>
					)
				} else if(thisChild.type==="list") {
					console.log(thisChild.children);
					listItemContent.push(
						<OrderedList key={j} content={thisChild} parseHTML={parseHTML} />
					)
				}						
			})}
			{listItemContent}
		</ol>
	)
}


/* Content blocks */

export function Paradigm({ content }) {
	return(
		<figure className="paradigm-wrapper">
			{content.template==="pronoun" && 
				<table className="paradigm-key-table">
					<tbody>
						<tr>
							<th>&nbsp;</th>
						</tr>
						<tr>
							<td>1.et.</td>
						</tr>
						<tr>
							<td>2.et.</td>
						</tr>
						<tr>
							<td>3.et.</td>
						</tr>
						<tr>
							<td>1.ft.</td>
						</tr>
						<tr>
							<td>2.ft.</td>
						</tr>
						<tr>
							<td>3.ft.</td>
						</tr>
					</tbody>
				</table>
			}
			<div className="card paradigm-card">
				<table className="paradigm-table">
					{content.headerRow && (
						<thead>
							<tr>
								{content.headerRow.cell.map((thisCell, i) =>
									<th key={i} colSpan={thisCell.colSpan ? thisCell.colSpan : ""} width={thisCell.width ? `${thisCell.width}%` : ""}>
										<RichTextBlockRenderer content={thisCell.content} parseHTML={true} />
									</th>
								)}
							</tr>
						</thead>
					)}					
					<tbody>
						{content.bodyRows.map((thisRow, i) =>
							<tr key={i} className={thisRow.dividerRow ? "divider-row" : ""}>
								{thisRow.cell.map((thisCell, i) =>
									<td 
										key={i}
										colSpan={thisCell.colSpan ? thisCell.colSpan : ""}
										rowSpan={thisCell.rowSpan ? thisCell.rowSpan : ""}
										width={thisCell.width ? `${thisCell.width}%` : ""}
									>
										<RichTextBlockRenderer content={thisCell.content} parseHTML={true} />
									</td>
								)}
							</tr>
						)}
					</tbody>
				</table>
			</div>
		</figure>
	)
}
