import { useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { DialogContext, BreadcrumbsContext, TrialContext } from './contexts';
import AuthProvider from './authProvider';

import './styles/variables.css';
import './styles/styles.css';

import Main from './main';

export default function App() {
		
	const [dialog, setDialog] = useState();
	
	const [breadcrumbs, setBreadcrumbs] = useState([
		{
			path: '/dashboard',
			title: 'Learn Icelandic',
			mobileStyle: 'hidden'
		}
	]);
	
  return (
		<HelmetProvider>
			<AuthProvider>
				<DialogContext.Provider value={{dialog, setDialog}}>
					<BreadcrumbsContext.Provider value={{breadcrumbs, setBreadcrumbs}}>
						<TrialContext.Provider value="eqan5aa0jn1uxiqjlnvgkvw0">
							<Main />
						</TrialContext.Provider>
					</BreadcrumbsContext.Provider>
				</DialogContext.Provider>
			</AuthProvider>
		</HelmetProvider>
  );
}